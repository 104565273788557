.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 2.8rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.program-categories {
    display: flex;
    gap: 1rem;
}

.category {
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
    cursor: pointer;
}

.category>:nth-child(1) {
    width: 2rem;
    height: 2rem;
    fill: white;
}

.category>:nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}

.category>:nth-child(3) {
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: black;
    font-weight: bold;
}

.category:hover {
    background: var(--planCard);
}

.right-arrow {
    width: 1rem;
    transform: rotate(180deg);
    animation: go-right 2s infinite;
    position: relative;
    transition: all 2s ease-in-out;
}

@keyframes go-right {
    from {
        left: 20%;
    }

    to {
        left: 0;
    }
}

/* RESPONSIVE STYLES */

/* Mobile Styles */

@media screen and (max-width: 768px) {

    .programs-header {
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }

    .program-categories{
        flex-direction: column;
    }

}